import {
    db,
    GET,
    firebase
} from '@/firebase.js'
import CompCard from '@/components/CompCard/CompCard.vue'
import Authblocker from '@/components/Authblocker/Authblocker.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Skeleton from '@/components/Skeleton/Skeleton.vue'
// const axios = require('axios');

export default {
    name: 'BadgeView',
    components: {
        CompCard,
        Authblocker,
        Tabs,
        Skeleton
    },
    data: function () {
        return {
            orgs: [],
            title: null,
            desc: null,
            type: null,
            icon: null,
            lastVisible: null,
            queryBusy: false,
            allOrgsFetched: false,
            showDefinition: false,
            activeTab: 'Achieved',
            tabs: ['Achieved', 'Missing'],
            filteredOrgs: [],
        }
    },
    methods: {
        /*         addSubcriberToMC2: function () {
                    console.log('Sending email to mc')
                    axios.get('https://us-central1-gokind-4f5cd.cloudfunctions.net/corsEnabledFunction?email=simonberglund19@gmail.com').then((res) => {
                        console.log(res)
                    })
                }, */
        resetRanking: function () {
            this.lastVisible = null
            this.queryBusy = false
            this.allOrgsFetched = false
            this.orgs = []
            this.filteredOrgs = []
        },
        switchTab: function (newTab) {
            this.activeTab = newTab
            this.resetRanking()
            this.getOrgsThatHasBadge()
        },
        getMoreOrgs: function () {
            this.getOrgsThatHasBadge(true)
        },
        getOrgsThatHasBadge: function (force = false) {
            if ((this.allOrgsFetched || this.queryBusy) && !force) return console.log('Cancel query')
            console.log('Fetching more badges')
            this.queryBusy = true
            let col = db.collection('orgs')
            if (this.activeTab !== 'Missing') col = col.where('badges', 'array-contains', db.doc('badges/' + this.$route.params.id))
            col = col.limit(5)
            // col = col.orderBy('name', 'asc')
            if (this.$event.id) col = col.where('events.' + this.$event.id, '==', true)
            if (this.lastVisible) col = col.startAfter(this.lastVisible)
            if (this.filteredOrgs.length === 0 && !force && (!this.$user || !this.$user.uid || !firebase.auth().currentUser) && this.lastVisible) {
                this.queryBusy = false
                return console.log('User is not authorized')
            }
            this.filteredOrgs = []
            GET(col, true).then(orgs => {
                this.lastVisible = orgs.docs[orgs.docs.length - 1]
                if (!this.lastVisible) {
                    console.log('All companies fetched')
                    return this.allOrgsFetched = true
                }
                orgs.forEach(async org => {
                    if (this.activeTab === 'Missing' && org.data().badges) {
                        console.log('Filter', org.id)
                        this.filteredOrgs.push(org.id)
                        if (org.data().badges.some(badge => badge.id === this.$route.params.id)) return
                    }
                    this.orgs.push({
                        logo: org.data().logo,
                        name: org.data().name,
                        oid: org.data().oid,
                        categories: org.data().categories,
                        bid: this.$route.params.id,
                        link: null,
                    })
                })
                this.queryBusy = false
                this.orgs.loaded = true
            })
        },
        // link: await this.getProof(this.$route.params.id, org),
        getProof: function (badgeId, org) {
            return new Promise((resolve) => {
                let col = db.collection('promises')
                col = col.where('badge', '==', db.doc('badges/' + badgeId))
                col = col.where('org', '==', org.id)
                col = col.orderBy('content', 'desc')
                GET(col).then(promises => {
                    if (promises.empty) return resolve(null)
                    let proofPath = 'https://demo.gokind.co/' + org.data().oid + '/' + promises.docs[0].data().content.path
                    return resolve({
                        path: proofPath,
                        label: 'Proof',
                        type: 'external'
                    })
                }).catch(error => {
                    console.log(error)
                    return resolve(null)
                })
            })
        },
        getBadge: function () {
            let doc = db.collection('badges').doc(this.$route.params.id)
            GET(doc).then(badge => {
                this.title = badge.data().title
                this.desc = badge.data().short_desc
                this.type = badge.data().type
                this.icon = badge.data().icon
                this.longDesc = badge.data().desc
            })
        },
        resetData: function () {
            this.title = null
            this.desc = null
            this.type = null
            this.icon = null
            this.longDesc = null
            this.lastVisible = null
            this.queryBusy = false
            this.allOrgsFetched = false
            this.showDefinition = false
            this.activeTab = 'Achieved'
        }
    },
    watch: {
        '$route.params.id': function () {
            if (this.$route.params.id && this.id !== this.$route.params.id && this.$route.name === 'Badge') {
                console.log('Reseting data')
                this.resetData()
                this.getBadge()
                this.getOrgsThatHasBadge()
                window.scrollTo(0, 0)
                this.orgs = this.orgs.filter(org => org.bid === this.$route.params.id)
            }
            if (this.$route.name === 'Badge') this.id = this.$route.params.id
        },
    },
    mounted: function () {
        console.log('Fetching badge', this.$route.params.id)
        this.getBadge()
    }
}