import Mat from '@/components/Mat/Mat.vue'
import {
    firebase
} from '@/firebase.js'

export default {
    name: 'Authblocker',
    props: {
        blockContent: Boolean,
        CTA2: [Function, String],
    },
    components: {
        Mat
    },
    data: function () {
        return {
            avatar: {
                type: 'icon',
                src: 'key-2-fill'
            },
            auth: false,
            features: [{
                label: 'Get fresh content first 🔥'
            }, {
                label: 'Join the community 🌍'
            }, {
                label: 'Decide new features 🚀'
            }]
        }
    },
    created: function () {
        firebase.auth().onAuthStateChanged(user => {
            this.auth = user
        });
    }
}